require('./bootstrap-setup');
import AOS from 'aos';
import axios from 'axios';
import CookieNotification from 'bb-cookie-notification';

new CookieNotification({
    privacyPage: 'https://locomotion.nl/privacyverklaring',
    targetBlank: true
});

AOS.init();

// Remove background-img border
document.querySelectorAll('img:not([src])').forEach((element) => {
    element.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
});

// Add and remove class on scroll
if(document.querySelector('nav')) {
    let scrollpos = window.scrollY
    const header = document.querySelector("nav")
    const headerHeight = header.offsetHeight

    const addClassOnScroll = () => header.classList.add("navbar-shrink")
    const removeClassOnScroll = () => header.classList.remove("navbar-shrink")

    window.addEventListener('scroll', function() {
        scrollpos = window.scrollY;

        if (scrollpos >= headerHeight) {
            addClassOnScroll()
        } else {
            removeClassOnScroll()
        }
    });
}

// Menu
if (document.querySelector('nav')) {

    const nav = document.querySelector('.navbar-toggler');
    nav.addEventListener("click", toggleMenu);

    function toggleMenu(){
        nav.classList.toggle('open-menu');
    }
}

const submitForm = (gRecaptchaResponse) => {
    const form = document.querySelector('.form');

    const submitButton     = form.querySelector('[type="submit"]');
    const currentInnerHtml = submitButton.innerHTML;
    submitButton.classList.add('disabled');
    submitButton.disabled  = true;
    submitButton.innerHTML = '<p class="d-flex justify-content-end align-items-end"><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Laden...</span></div></p>';

    const formData = new FormData(form);
    formData.append('g-recaptcha-response', gRecaptchaResponse);

    const validationAlert = document.getElementById('form-validation');
    validationAlert.classList.add('d-none');
    validationAlert.classList.remove('alert-danger');

    document.querySelectorAll('.invalid-feedback').forEach((element) => {
        if(!element.classList.contains('d-none')) {
            element.classList.add('d-none');
        }
    });

    document.querySelectorAll('.is-invalid').forEach((element) => {
        element.classList.remove('is-invalid');
    });

    axios.post(form.action, formData).then((response) => {
        if(response.data.success) {
            validationAlert.innerHTML = response.data.message;
            validationAlert.classList.add('alert-success');
            validationAlert.classList.remove('d-none');

            form.innerHTML = '';
        }
    }).catch((exception) => {
        if(exception.response.data.errors) {
            for(let error in exception.response.data.errors) {
                const el = form.querySelector('[id="' + error + '"]');
                el.classList.add('is-invalid');
                const feedback = el.parentNode.parentNode.querySelector('.d-none') || el.parentNode.querySelector('.d-none');
                if(feedback !== null) {
                    feedback.classList.remove('d-none');
                }
            }
        }
    }).finally(() => {
        submitButton.classList.remove('disabled');
        submitButton.disabled  = false;
        submitButton.innerHTML = currentInnerHtml;
    });
}

window.submitForm = submitForm;